<template>
  <div class="form-container">
    <div v-if="successful" class="mb-4">
      <h1 class="mb-3">We sent you a mail!</h1>
      <p>We have sent a verification link to your mail, kindly click the button to verify. (Check spam if you can't seem to find it)</p>
    </div>
    <div v-else class="mb-4">
      <h1 class="mb-3">Hello!</h1>
      <p>Sign up to access your account dashboard</p>
    </div>

    <div class="form-group">
      <label for="">Full Name</label>
      <input v-model="payload.name" type="text" class="form-control" placeholder="Full Name" />
    </div>

    <div class="form-group">
      <label for="">Email Address</label>
      <input v-model="payload.email" type="email" class="form-control" placeholder="e.g someone@example.com" />
    </div>

    <div class="form-group">
      <label for="">Password</label>
      <div class="with-icon">
        <input v-model="payload.password" :type="passwordType" class="form-control" />
        <span class="-icon m-cursor-pointer" @click="visiblePass = !visiblePass">
          <font-awesome-icon :icon="visiblePass ? faEye : faEyeSlash" />
        </span>
      </div>
    </div>

    <div class="form-group">
      <label for="">Confirmation Password</label>
      <div class="with-icon">
        <input v-model="payload.password_confirmation" :type="passwordConfirmType" class="form-control" />
        <span class="-icon m-cursor-pointer" @click="visiblePassword = !visiblePassword">
          <font-awesome-icon :icon="visiblePassword ? faEye : faEyeSlash" />
        </span>
      </div>
    </div>

    <b-alert v-for="(err, i) in errors" :key="i" v-model="err.visible" class="mt-4" variant="danger" show dismissible>
      {{ err.message }}
    </b-alert>

    <div class="form-group m-mt-40">
      <b-button block variant="primary" :class="classObject" :disabled="btnDisabled" @click="signUp()">
        {{ processing? "": "Submit" }}
        <RingLoader v-if="processing" />
      </b-button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import setErrorMixin from "@/mixins/setErrorMixin";
import RingLoader from "@/components/loader/RingLoader";

export default {
  title: "Menaget - Talent Dashboard Sign In Page",
  components: { FontAwesomeIcon, RingLoader },
  mixins: [setErrorMixin],
  data() {
    return {
      faEye,
      faEyeSlash,
      visiblePass: false,
      visiblePassword: false,
      errors: [],
      successful: false,
      payload: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        rememberMe: false,
      },
    };
  },
  computed: {
    btnDisabled() {
      return this.payload.name === "" || this.payload.email === "" || this.payload.password === "" || this.payload.password_confirmation === "" || this.payload.password !== this.payload.password_confirmation || this.processing;
    },
    passwordType() {
      return this.visiblePass ? "text" : "password";
    },
    passwordConfirmType() {
      return this.visiblePassword ? "text" : "password";
    },
  },
  methods: {
    signUp() {
      this.processing = true;
      this.$store
        .dispatch("auth/signUp", this.payload)
        .then(() => {
          this.successful = true
        })
       .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
